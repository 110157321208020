$(document).ready(() => {
  // 根据 video__list-content-item 的宽度计算 Video 元素的高度
  const listItem = $('.video__list-content-item');
  const videoElement = $('.video__list-content-video');
  videoElement.css('height', `${Number(listItem.width() ?? 0) ? Number(listItem.width()) * 0.657 + 'px' : 'auto'}`);

  const playButton = $('.video__list-content-video-play');
  playButton.on('click', function () {
    const playerId = $(this).data('id');
    const videoId = $(this).data('video-id');
    if (playerId) {
      const videoPlayer = $(`#${playerId}`);
      const videoControl = $(`#videoControl${videoId}`);
      const videoStatus = videoPlayer.prop('paused');
      // 按下播放按钮，当播放器状态暂停时播放视频并展示原生控件
      if (videoStatus) {
        videoPlayer.trigger('play');
        videoPlayer.prop('controls', true);
        // 隐藏自定义播放控制
        videoControl.css('display', 'none');
      }
    }
  });
});
