import Swiper, { SwiperOptions } from 'swiper';
import { changeTabDisplay } from '@hagongyi/oc-mainsite-utilities';
import { pdfUrlClick } from '../../utils/pdf';

$(document).ready(() => {
  //#region 首页 Banner 图
  // 首页 Banner 配置
  const bannerSwiperOptions: SwiperOptions = {
    autoHeight: true,
    loop: true,
    autoplay: {
      delay: 5000,
    },
  };
  // 首页 Banner 初始化滚动效果
  new Swiper('.index__banner-container', { ...bannerSwiperOptions });
  //#endregion

  //#region 首页项目模块
  const projectSwiperOptions: SwiperOptions = {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    allowTouchMove: true,
    // slidesPerView: 1,
    // spaceBetween: 10,
    // navigation:{
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    // on:{
    //   slideChangeTransitionEnd:function (){
    //     // @ts-ignore
    //     if(this.isEnd){
    //       // @ts-ignore
    //       this.navigation.$prevEl.addClass('swiper-button-prev').removeClass('swiper-button-prev-disable');
    //       // @ts-ignore
    //       this.navigation.$nextEl.addClass('swiper-button-next-disable').removeClass('swiper-button-next');
    //     }else{
    //       // @ts-ignore
    //       this.navigation.$prevEl.addClass('swiper-button-prev-disable').removeClass('swiper-button-prev');
    //       // @ts-ignore
    //       this.navigation.$nextEl.addClass('swiper-button-next').removeClass('swiper-button-next-disable');

    //     }
    //   }
    // },
    // breakpoints: {
    //   991.98: {
    //     allowTouchMove: false,
    //     slidesPerView: 4,
    //     spaceBetween: 18,
    //   },
    // },
    pagination: {
      el: '.index__project-pagination',
      clickable: true,
    },
  };
  new Swiper('.index__project-container', { ...projectSwiperOptions });
  //#endregion

  //#region 首页新闻模块
  const newContentSwiperOptions: SwiperOptions = {
    loop: true,
    autoplay: {
      delay: 5000,
    },
    allowTouchMove: true,
    slidesPerView: 2,
    spaceBetween: 10,
    pagination: {
      el: '.index__media-news-content-pagination',
      clickable: true,
    },
  };
  new Swiper('.index__media-news-content', { ...newContentSwiperOptions });
  //#endregion

  //#region 首页视频模块
  // 根据 index__media-video-content 的宽度计算 Video 元素的高度
  const listItem = $('.index__media-video-content');
  const videoElement = $('.index__media-video-content-player');
  videoElement.css(
    'height',
    `${
      Number(listItem.width() ?? 0)
        ? Number(listItem.width()) * 0.6117 + 'px'
        : 'auto'
    }`
  );
  // 鼠标进入则自动播放
  listItem.on('mouseover', function () {
    playButton.trigger('click');
  });
  // 点击播放按钮播放视频
  const playButton = $('.index__media-video-content-controls-play');
  playButton.on('click', function () {
    const playerId = $(this).data('id');
    const videoId = $(this).data('video-id');
    if (playerId) {
      const videoPlayer = $(`#${playerId}`);
      const videoControl = $(`#videoControl${videoId}`);
      const videoStatus = videoPlayer.prop('paused');
      // 按下播放按钮，当播放器状态暂停时播放视频并展示原生控件
      if (videoStatus) {
        videoPlayer.trigger('play');
        videoPlayer.prop('controls', true);
        // 隐藏自定义播放控制
        videoControl.css('display', 'none');
      }
    }
  });

  //#endregion

  //#region 首页公示模块
  // 获取所有的 Tab Radio
  const announceTabRadio = $('input:radio[name=我们的公示]');
  // 获取所有 Tab Radio 的 Value
  const announceTabRadioValues: string[] = [];
  announceTabRadio.each(function (index, value) {
    const element = $(value);
    // 如果元素的 data-checked 是 'true'
    if (element.data('checked')) {
      // 设置该元素的 checked 属性为 true
      element.prop('checked', true);
      // 页面加载时切换到选择的 Tab
      changeTabDisplay([], `${element.val()}`);
    }
    announceTabRadioValues.push(`${$(value).val()}`);
  });
  // 当点击 Tab Radio 时切换 Tab 的显示
  announceTabRadio.change(function () {
    const checkedRadioValue = `${$(this).val()}`;
    changeTabDisplay(announceTabRadioValues, checkedRadioValue);
  });
  // 当 index__announce-content-tab-item 点击时获取 data-pdfUrl
  $('.index__announce-content-tab-item').click(function () {
    const pdfUrl: string = `${$(this).attr('data-pdfUrl')}`;
    pdfUrlClick(pdfUrl);
  });
  //#endregion

  //#region 首页捐赠模块
  const donateSwiperOptions: SwiperOptions = {
    autoHeight: false,
    loop: true,
    autoplay: {
      delay: 2000,
    },
    direction: 'vertical',
  };
  new Swiper('.index__donate-info-container', { ...donateSwiperOptions });
  //#endregion
});

