import { changeTabDisplay } from '@hagongyi/oc-mainsite-utilities'

$(document).ready(() => {
  // 获取所有的 Tab Radio
  const partnerTabRadio = $('input:radio[name=合作伙伴]');
  // 获取所有 Tab Radio 的 Value
  const partnerTabRadioValues: string[] = [];
  partnerTabRadio.each(function(index, value) {
    partnerTabRadioValues.push(`${$(value).val()}`);
  });
  // 获取当前页面的 Hash
  const windowHash = decodeURIComponent(window.location.hash);
  const currentTab = windowHash.replace('#', '')
  // 页面加载时切换到选择的 Tab
  changeTabDisplay(partnerTabRadioValues, currentTab);
  partnerTabRadio.each(function(index, value) {
    const element = $(value);
    if (element.val() === currentTab) {
      element.prop('checked', true);
    }
  });
  // 当点击 Tab Radio 时切换 Tab 的显示
  partnerTabRadio.change(function() {
    const checkedRadioValue = `${$(this).val()}`;
    changeTabDisplay(partnerTabRadioValues, checkedRadioValue);
  });
});
