let lastY = 0
$(window).scroll(e => {
    const toolBar = $('.header__toolBar');
    // 隐藏和展示搜索条
    lastY = e.currentTarget.scrollY
    if (e.currentTarget.scrollY > 100) {
        toolBar.fadeOut(1000);
    } else if (lastY <= 10 || lastY > 100) {
        toolBar.fadeIn(500);
        lastY = 0
    }
});
