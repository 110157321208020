import { browserSession } from '@hagongyi/oc-mainsite-utilities'
import { PDF_URL_KEY_NAME } from './constants'

const pdfUrlClick = (pdfUrl: string) => {
  browserSession.setItem(PDF_URL_KEY_NAME, pdfUrl);
  location.href = '/pdf';
}

export default pdfUrlClick;
export { pdfUrlClick };
