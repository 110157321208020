import {pdfUrlClick} from '../../utils/pdf'
import {downloadClick} from '../../utils/download'

$(function() {
    const previewBtn = $('.operation_btn__preview');
    const downloadBtn = $('.operation_btn__download');
    $('.operation_btn__download,.operation_btn__preview').on('click', function () {
        const link: string = `${$(this).attr('data-link')}`;
        const fileName: string = $(this).attr("data-filename") ?? '';
        if ($(this).hasClass('operation_btn__preview')) {   // 预览PDF
            $(this).attr('data-type') == 'pdf' ? pdfUrlClick(link) : window.open(link)
        } else {    // 下载文件
            downloadClick(link, fileName);
        }
    });
});
