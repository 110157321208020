import { changeTabDisplay } from '@hagongyi/oc-mainsite-utilities'
import { pdfUrlClick } from '../../utils/pdf'

$(document).ready(function () {
  // 获取所有的 Tab Radio
  const informationTabRadio = $('input:radio[name=信息公开]');
  // 获取所有 Tab Radio 的 Value
  const informationTabRadioValues: string[] = [];
  informationTabRadio.each(function(index, value) {
    const element = $(value);
    // 如果元素的 data-checked 是 'true'
    if (element.data('checked')) {
      // 设置该元素的 checked 属性为 true
      element.prop('checked', true);
      // 页面加载时切换到选择的 Tab
      changeTabDisplay([], `${element.val()}`);
    }
    informationTabRadioValues.push(`${$(value).val()}`);
  });
  // 当点击 Tab Radio 时切换 Tab 的显示
  informationTabRadio.change(function() {
    const checkedRadioValue = `${$(this).val()}`;
    changeTabDisplay(informationTabRadioValues, checkedRadioValue);
  });
  // 当 information__pdf-tab-item 点击时获取 data-pdfUrl
  $('.information__pdf-tab-item').click(function () {
    const pdfUrl: string = `${$(this).attr('data-pdfUrl')}`;
    pdfUrlClick(pdfUrl);
  });
  $('.information__list-content-item').click(function () {
    const pdfUrl: string = `${$(this).attr('data-pdfUrl')}`;
    pdfUrlClick(pdfUrl);
  });
});
