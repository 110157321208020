const downloadClick = (fileUrl: string, fileName: string) => {
    let xmlHttpRequest = new XMLHttpRequest();
    xmlHttpRequest.open("GET", fileUrl, true);
    xmlHttpRequest.responseType = 'blob';
    xmlHttpRequest.onload = function (e) {
        let url = window.URL.createObjectURL(xmlHttpRequest.response)
        let a = document.createElement('a');
        a.href = url
        a.download = fileName;
        a.click()
    }
    xmlHttpRequest.send();
}

export default downloadClick;
export {downloadClick};
