import './vendor/bootstrap'
import '@hagongyi/oc-mainsite-utilities/components/oc-page-banner.htm'
import '@hagongyi/oc-mainsite-utilities/components/oc-bread-nav.htm'
import '@hagongyi/oc-mainsite-utilities/components/oc-pagination.htm'
import '@hagongyi/oc-mainsite-utilities/components/oc-article-nav.htm'
import './layouts/header'
import './pages/index/index'
import './pages/video/list'
import './pages/partner/index'
import './pages/information/index'
import './pages/project/detail'
